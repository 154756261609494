import React, { Component } from "react";
import { NavLink, Link , withRouter } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import globalData from "../config.json";
import $ from "jquery";
class Submenu_mobile extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      event: [],
    };
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  /* handleMenuClose = (props) => {
    // Call the closeMobileMenu function from the prop
    props.closeMobileMenu();
  } */
  componentDidMount() {
    this.getEvents();
  }

  getEvents() {
    axios.get(globalData.apiUrl + "/api/event-types").then((response) => {
      this.setState({
        event: [...response.data.data],
        loading: false,
      });

      // console.log(this.state.event);
    });
  }

  /* closeMobileMenu(e) {
    // e.preventDefault();
    // console.log("close");
    // THEME.mobilemenupush._updateActiveMenu("back");
    // THEME.mobilemenupush._updateActiveMenu("back");
    // $('.mobilemenu').toggleClass('active');
    // $('.mobilemenu-toggle').toggleClass('active');
    // $('body').toggleClass('slidemenu-open');
  } */
  closeMobileMenu() {
    // e.preventDefault();
    //  console.log("close");
    $(".mobilemenu").toggleClass("active");
    $(".mobilemenu-toggle").toggleClass("active");
    $("body").toggleClass("slidemenu-open");
    //$(".nav-level-1").addClass("stay-level-1");
    // $(".nav-level-1").css("transform", "translateX(0%)");
    $(".mmenu-submenu-active")
      .removeClass("mmenu-submenu-active")
      .removeClass("mmenu-submenu-open");
    $(".back-visible").removeClass("back-visible");
    const script = document.getElementById("myScript");
    // console.log(" SUb MENU SCRIPT", script);
    if (script) {
      // The script element exists, so it's safe to remove it
      //script.parentNode.removeChild(script);
      script.parentNode = null;
    } else {
      // The script element with the specified ID was not found
      // console.log("Script element not found.");
    }
  }
  handleLinkClick = (e) => {
    console.log("close");
    $(".mobilemenu").toggleClass("active");
    $(".mobilemenu-toggle").toggleClass("active");
    $("body").toggleClass("slidemenu-open");
    //$(".nav-level-1").addClass("stay-level-1");
     $(".nav-level-1").css("transform", "translateX(0%)");
    $(".mmenu-submenu-active")
      .removeClass("mmenu-submenu-active")
      .removeClass("mmenu-submenu-open");
    $(".back-visible").removeClass("back-visible");
    // alert("in handleLinkClick");
   
   //e.preventDefault(); // Prevent the default page reload behavior
   
  
  //  this.closeMobileMenu(); // Call your custom function

    // You can also perform other actions here if needed
  };

  render() {
    return (
      <ul className="nav-level-2">
      
     {/*  <Link to={`/contact-us/test`} className="closeLink"
                        onClick={this.handleLinkClick}
                       >
 TESTSTTT
</Link> */}
        {this.state.event.map((e, index) => {
          return e.events.length > 0 ? (
            <li key={e.id}>
              <Link to={`/${e.handle}`}>
                {e.name}
                <span className="arrow">
                  <i className="icon-angle-right"></i>
                </span>
              </Link>
              <ul className="nav-level-3" key={e.idEncrypted}>
                {e.events.map((ev, index) => {
                  return (
                    <li key={ev.id}>
                      {/*   <Link to={`/contact-us/test`}
                         onClick={this.handleLinkClick}
                       >
 TESTSTTT
</Link> */}
                      {ev.event_status == 1 ? (
                        <Link
                          //onClick={this.closeMobileMenu}
                          onClick={this.handleLinkClick}
                          to={`/${e.handle}/${ev.handle}`}
                          state={{
                            id: e.idEncrypted,
                            event_id: ev.idEncrypted,
                          }}
                        >
                          {ev.event}
                        </Link>
                      ) : (
                        <Link to="">{ev.event}</Link>
                      )}
                    </li>
                  );
                })}
              </ul>
            </li>
          ) : (
            " "
          );
        })}
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeUser: () => dispatch({ type: "USER", value: "guest" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Submenu_mobile);
