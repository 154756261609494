import React, { Component } from "react";
import Swal from "sweetalert2";
import globalData from "../config.json";
import { BoxLoading } from "react-loadingg";
import Loading from "./Loading";
import axios from "axios";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';



class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      event: "",
      description: "",
      events: [],
      loading: false,
      errors: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  loadScript() {
    window.scrollTo(0, 0);

    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration
    // console.log(" Contact Loadscript)", scripts);
    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });

    }
  }
   loadScript1 = () => {
    let scripts = [{ src: globalData.appUrl + "front/js/app-html.js" }];

    //Append the script element on each iteration
    // console.log(" Contact Loadscript)", scripts);
    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });

    const script = document.getElementById("myScript");
    if (script === undefined) {
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: globalData.apiUrl + "/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration
      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });

    }
  };
  componentDidMount() {
    
    this.loadScript1();
    document.title = 'Contact Us';
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    ReactPixel.init(globalData.metaFacebookKey);
    ReactPixel.pageView(window.location.pathname);
    // window.addEventListener("scroll", this.handleScroll);
    this.getEvents();
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    e.target.reset();

    if (this.validateForm()) {
      this.setState({
        loading: true,
      });
      axios
        .post(globalData.apiUrl + "/api/contact-us", {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          phone: this.state.phone,
          event: this.state.event,
          description: this.state.description,
        })
        .then((response) => {
          this.setState({
            firstName: "",
            lastName: "",
            // email: "",
            phone: "",
            event: "",
            description: "",
            loading: false,
          });

          Swal.fire({
            title: "Thank you for your enquiry. We will be in contact soon.",
            showConfirmButton: false,
            icon: "success",
            timer: 3000,
            timerProgressBar: true,
          });

          // Swal.fire("Thank you for your enquiry. We will be in contact soon.", "", "success");
          // localStorage.setItem('token', response.data.token)
          // this.props.addUser(response.data.user)
          // this.setState({presistAddress: false})
        })
        .catch((err) => {
          // setErrorKeys(Object.keys(JSON.parse(err.response.data)));
          // setError(JSON.parse(err.response.data));
          // setLoading(false);
        });
    }
  }
  validateForm() {
    let errors = [];
    let formIsValid = true;

    if (!this.state.firstName) {
      formIsValid = false;
      errors["first_name"] = "*Please enter your first name.";
    }

    if (typeof this.state.firstName !== "undefined") {
      if (!this.state.firstName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["first_name"] = "*Please enter alphabet characters only.";
      }
    }

    if (!this.state.lastName) {
      formIsValid = false;
      errors["last_name"] = "*Please enter your surname.";
    }

    if (typeof this.state.lastName !== "undefined") {
      if (!this.state.lastName.match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["last_name"] = "*Please enter alphabet characters only.";
      }
    }

    if (!this.state.email) {
      formIsValid = false;
      errors["email"] = "*Please enter email address.";
    }

    if (typeof this.state.email !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        formIsValid = false;
        errors["email"] = "*Please enter a valid email address.";
      }
    }

    if (!this.state.phone) {
      formIsValid = false;
      errors["phone"] = "*Please enter your  telephone number.";
    }

    if (typeof this.state.phone !== "undefined") {
      var pattern = new RegExp(/^[+0-9\b]+$/);
      if (!pattern.test(this.state.phone)) {
        formIsValid = false;
        errors["phone"] = "*Please enter a valid telephone number.";
      }
    }

    if (!this.state.event) {
      formIsValid = false;
      errors["event"] = "*Please select a tour or event.";
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }
  componentDidUpdate() {}

  componentWillUnmount() {
    //const script = document.getElementById("myScript");
    //script.parentNode.removeChild(script);
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }
  getEvents() {
    // alert("in getEvents");
    // return false;
    axios.get(globalData.apiUrl + "/api/events").then((response) => {
      this.setState({
        events: [...response.data.data],
      });
    });
  }

  render() {
    return (
      <>
        <div
          id="main-wrapper"
          className="tour-contactus bg-dark for-padding-review contact-us-page pb-1"
        >
          <div className="container mt-5">
            {/* <div className="row text-center">
                    <div className="col-lg">
                        <h1 className="text-center text-white for-mobile-heading">We’re Here to Help You</h1>
                    </div>
                </div> */}
            {this.state.loading ? <Loading /> : ""}
            <div className="row d-flex justify-content-center">
              <div className="col-md-10 pb-md-5 px-0">
                <form id="contact-us" onSubmit={this.handleSubmit}>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        onChange={this.handleChange}
                        value={this.state.firstName}
                        id="inputEmail4"
                        placeholder="First Name"
                      />
                      {this.state.errors.first_name && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <strong>{this.state.errors.first_name}</strong>
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        onChange={this.handleChange}
                        value={this.state.lastName}
                        id="inputPassword4"
                        placeholder="Last Name"
                      />
                      {this.state.errors.last_name && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <strong>{this.state.errors.last_name}</strong>
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        onChange={this.handleChange}
                        value={this.state.email}
                        id="inputEmail"
                        placeholder="Email"
                      />
                      {this.state.errors.email && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <strong>{this.state.errors.email}</strong>
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        onChange={this.handleChange}
                        value={this.state.phone}
                        id="inputPassword"
                        placeholder="Tel Number"
                      />
                      {this.state.errors.phone && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <strong>{this.state.errors.phone}</strong>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <select
                        required=""
                        name="event"
                        id="event-selector"
                        onChange={this.handleChange}
                        value={this.state.event}
                        className="form-control"
                      >
                        <option value="0" defaultValue="0">
                          Select Tour
                        </option>
                        {this.state.events.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.event}
                          </option>
                        ))}
                      </select>
                      {this.state.errors.event && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          <strong>{this.state.errors.event}</strong>
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-12">
                      <textarea
                        className="form-control"
                        name="description"
                        rows="6"
                        onChange={this.handleChange}
                        value={this.state.description}
                        placeholder="Comments"
                      ></textarea>
                    </div>
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-primary w-100">
                        Submit Your Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="row py-md-5 for-mobile-res">
              <div className="col-md-6 text-center for-border-right">
                <img src={ globalData.apiUrl + "/front/images/address.svg"} />
                <h3 className="text-white">Twynhams Tours </h3>
                <h5 className="text-white">
                  Unit 11, The Beaver Centre, Great Central Way, Woodford Halse,
                  <br />
                  Daventry, NN11 3DP.
                </h5>
              </div>
              <div className="col-md-6 text-center">
              <img src={ globalData.apiUrl + "/front/images/call-services.svg"} />
                
                <h3 className="text-white">
                  To speak to one of our experts call
                </h3>
                <h5 className="text-white mb-2">UK : 0333 303 4442
                 {/* 0800 5999 077 */}
                </h5>
                <h5 className="text-white"> INT'L : +44 333 303 4442 
                {/* 800 5999 077 */}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
