import React, { useEffect, useState, Component } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import globalData from "../config.json";
import Loading from "./Loading";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactGA from 'react-ga';
import $ from 'jquery';
import ReactPixel from 'react-facebook-pixel';;

const cookies = new Cookies();

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ChildEventdetail extends Component {
  constructor(props) {
    super(props);
    //  4console.log(props, 'gggggggggggggggggggggggggggggggggggggggggggg------------------------------');
    this.state = {
      loading: true,
      packageData: [],
      Data: {},
      eventTabData: [],
      mode: "",
      activeIndex: [],
      cartItemCount: 0,
      ip: "",
      redirectUrl: "",
      theposition: "",
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.handleScrollBottom = this.handleScrollBottom.bind(this);

  }

  getData() {
    axios
      .get(globalData.apiUrl + "/api/getIpaddress", {})
      .then((result) => {
        this.setState({
          ip: result.data.data,
        });
      })
      .catch((error) => {});
  }

  getguestAuth() {
    axios
      .get(globalData.apiUrl + "/api/cart", {})
      .then((result) => {})
      .catch((error) => {
        cookies.set("guesttoken", error.response.data.generatedUserId, {
          path: "/",
        });
      });
  }

  addToCart(id, event) {
    let token = "";
    let guesttoken = "";
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      guesttoken = cookies.get("guesttoken");
    }
    // console.log(config);
    axios
      .post(
        globalData.apiUrl + "/api/cart",
        {
          packageId: id,
          isInstallments: 0,
          ipAddress: this.state.ip,
          paramId: guesttoken,
          slugEventType: this.props.match.params.slug,
        },
        config
      )
      .then((result) => {
        // window.location.reload()
        if ( typeof result.data.data.countCartItems !== "undefined")
        {
          
          Swal.fire({
            title:result.data.message,
            showConfirmButton: false,
            icon:"success",
            timer: 3000,
            timerProgressBar: true,
          });
          // Swal.fire(result.data.message, "", "success");
          this.props.updateCartCount(result.data.data.countCartItems);
          this.props.updateCartQuantity(result.data.data.countCartItemsQuantity);
        }else{

          Swal.fire(result.data.message, "", "warning");
          
          
        }
        
        // this.props.updateCartid(12);

       
        // this.handleScroll();
      })
      .catch((err) => console.error(err));
  }

  addToCartIns(id, event) {
    let token = "";
    let guesttoken = "";
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    if (localStorage.getItem("token")) {
      token = localStorage.getItem("token");
      config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      guesttoken = cookies.get("guesttoken");
    }
    // console.log(config);
    axios
      .post(
        globalData.apiUrl + "/api/cart",
        {
          packageId: id,
          isInstallments: 1,
          ipAddress: this.state.ip,
          paramId: guesttoken,
          slugEventType: this.props.match.params.slug,
        },
        config
      )
      .then((result) => {

        if ( typeof result.data.data.countCartItems !== "undefined")
        {
          Swal.fire({
            title:result.data.message,
            showConfirmButton: false,
            icon:"success",
            timer: 3000,
            timerProgressBar: true,
          });
          // Swal.fire(result.data.message, "", "success");
          this.props.updateCartCount(result.data.data.countCartItems);
          this.props.updateCartQuantity(result.data.data.countCartItemsQuantity);
        }else{
          
          Swal.fire(result.data.message, "", "warning");
          
          
        }
        // console.log(result);
        
      })
      .catch((err) => console.error(err));
  }

 async componentDidMount() {

  
  window.scrollTo(0, 0);
   
    await this.getEventData();
    await this.getData();
    document.title = this.slugToTitle(this.props.match.params.slug2);
    ReactGA.initialize(globalData.googleAnalyticKey);
    ReactGA.pageview(window.location.pathname);
    if (cookies.get("guesttoken") == undefined) {
      this.getguestAuth();
    }
    window.addEventListener('scroll', this.listenToScroll)
    
   
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  handleScrollBottom() {
    // console.log(document.body.offsetHeight);
    window.scroll({
      top: 1000,
      left: 0,
      behavior: "smooth",
    });
  }

  componentDidUpdate() {
   
   
    // console.log(this.state.Data.handle, "componentDidUpdate");
    if (
      typeof this.state.Data.handle !== "undefined" &&
      this.props.match.params.slug2 != this.state.Data.handle
    ) {
      this.getEventData();
      this.getData();
      window.scrollTo(0, 0);
      document.title = this.slugToTitle(this.props.match.params.slug2);
     
      
    }
    // if ($(".mmenu-item--mega").hasClass("hovered")) {
    //   $(".mmenu-item--mega").removeClass("hovered");
    // } else {
    //   $(".mmenu-item--mega").removeClass("hovered");
    // }
    if (cookies.get("guesttoken") == undefined) {
      this.getguestAuth();
    }
    const script = document.getElementById("myScript");

    if (script == undefined) {
      // console.log("ffffffff add");
      let scripts = [
        {
          src: "https://dev.twynhamstours.co.uk/twynhams-tours/public/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    } else {
      // console.log("ffffffff addaaaaaaaaa remove");
      script.parentNode.removeChild(script);
      let scripts = [
        {
          src: "https://dev.twynhamstours.co.uk/twynhams-tours/public/front/js/app-html.js",
        },
      ];
      //Append the script element on each iteration

      scripts.forEach((item) => {
        const script = document.createElement("script");
        script.id = "myScript";
        script.src = item.src;
        script.async = true;
        document.body.appendChild(script);
      });
    }
  }

  componentWillUnmount() {
    const script = document.getElementById("myScript");
    script.parentNode.removeChild(script);
    window.removeEventListener('scroll', this.listenToScroll)
  }

  listenToScroll = () => {
    localStorage.setItem("scrollpos",  window.scrollY);
  
   
  }

  getEventData() {
    // console.log("getEventData");
    axios
      .get(
        globalData.apiUrl +
          `/api/events/${this.props.match.params.slug}/${this.props.match.params.slug2}`
      )
      .then((response) => {
      
        if (response.data.data.isEventTypeExist == undefined) {
         
         if (typeof response.data.data.infoEvent !== "undefined") {
            this.setState({
              packageData: [...response.data.data.packages],
              Data: { ...response.data.data.infoEvent },
              eventTabData: [...response.data.data.infoEvent.eventTabsData],
              loading: false,
              isParentTourExist: false,
            });
            console.log(this.state,'ssss');
            if (window.performance) {
              if (performance.navigation.type == 1) {
                let scrollpos = localStorage.getItem('scrollpos');
                 if (scrollpos) window.scrollTo(0, scrollpos);
              } else {
                // console.log( "This page is not reloaded");
              }
            }
            
          } else {

            console.log(this.state,'ssssssssssss');
            this.setState({
              redirectUrl:
                globalData.redirectUrl + this.props.match.params.slug,
            });
          }
        } else if (response.data.data.isEventTypeExist) {
          this.setState({ redirectUrl: this.props.match.params.slug });
        } else {
          this.setState({ redirectUrl: "not-found" });
        }

        // console.log(eventTabData);
        // window.scrollTo(0, 0);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.error(err)
      }
      
      
      );
  }

  render() {
    let dynamicClass = "";
    let html = "";
    let count = 0;
    let eventtab = this.state.eventTabData;
//  console.log("dddddddddddddddddddddd", this.state.redirectUrl);
    if (this.state.redirectUrl != "") {
      return <Link to={"/" + this.state.redirectUrl} />;
    }
    
      if (this.state.loading) {
        return (
          <Loading />
        )
      }
     

    
      if (this.state.eventTabData.length > 0) {
        // console.log("In if render ", this.state.eventTabData.length);
        return (
          <>
            <div
              className="page-content motorsport_tours event-title-page"
              key={this.state.Data.id}
            >
              {this.state.Data.video_banner != "" ||
              (this.state.Data.banner_title != "" &&
                this.state.Data.banner_description != "" &&
                this.state.Data.eventDateFormatted != "") ? (
                <div className="holder fullwidth full-nopad mt-0">
                  <div className="container">
                    {this.state.Data.video_banner == null &&
                    this.state.Data.video_banner == undefined ? (
                      <div className="bnslider-wrapper">
  
                        <Carousel
                            autoPlay
                            showArrows={false}
                            showThumbs={false}
                            infiniteLoop={false}
                            emulateTouch={true}
                          >
                            <div className="bnslider-slide1 ">
                              <img className="" src={this.state.Data.imageMainUrl} />
                              <div className="bnslider-text-wrap bnslider-overlay">
                                <div className="tour-eventdetail-page bnslider-text-content txt-middle txt-left txt-middle-m txt-center-m">
                                  <div className="bnslider-text-content-flex">
                                    <div className="bnslider-vert for-mobile-padding tour-envent-title-mobile-hide">
                                      <div className="text-content-slider m-auto">
                                        <div
                                          className="bnslider-text order-1 mt-0 bnslider-text--md text-center data-ini"
                                          data-animation="fadeInUp"
                                          data-animation-delay={800}
                                          data-fontcolor="#fff"
                                          data-fontweight={700}
                                          data-fontline="1.5"
                                        >
                                          {this.state.Data.banner_title}
                                        </div>
                                        <div
                                          className="bnslider-text order-2 mt-sm bnslider-text--xs text-left data-ini"
                                          data-animation="fadeInUp"
                                          data-animation-delay={800}
                                          data-fontcolor="#fff"
                                          data-fontweight={700}
                                          data-fontline="1.5"
                                        >
                                          {this.state.Data.banner_description}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="banner-scroll-down-link shimmer hide-mobile"
                                      onClick={this.handleScrollBottom}
                                    >
                                      <i className="fa fa-angle-double-down" />
                                      <br />
                                      Scroll Down
                                    </div>
  
                                    {/* <div className="text-center">
                                                            <a href="#motorsport_tours"><i className="icon-arrow-down text-white" style={{fontSize: 30}} /></a>
                                                        </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </Carousel>
                                                      </div>
                    ) : (
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                          width="100%"
                          height="auto"
                          src={this.state.Data.videoBannerYouTubeEmbedUrl}
                          title="YouTube video player"
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div id="motorsport_tours" className="bg-dark">
                <div className="container-fluid" key="1">
                  <div className="row">
                    <div className="col-md-12 text-center text-white">
                      <div className="content-box-1">
                        <h1 className="pb-4 p1-heading">
                          {this.state.Data.event}
                        </h1>
                        {ReactHtmlParser(this.state.Data.content)}
                      </div>
                    </div>
                  </div>
                </div>
  
                {this.state.eventTabData.map((et, index) => {
                  {
                    html =
                      et.tab == "video" ? (
                        <>
                          {et.onlyVideoFileUrl != "" ? (
                            <div className="video-box-1" key={index}>
                              <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                  width="100%"
                                  height="auto"
                                  src={et.onlyVideoFileUrl}
                                  title="YouTube video player"
                                  frameBorder={0}
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {et.onlyVideoVimeoFileUrl != "" ? (
                            <div className="video-box-2 my-2">
                              <div className="embed-responsive embed-responsive-16by9">
                                <iframe
                                  width="100%"
                                  height="auto"
                                  src={et.onlyVideoVimeoFileUrl}
                                  title="YouTube video player"
                                  frameBorder={0}
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                      ) : et.tab == "box" ? (
                        <div className="container-fluid" key={index}>
                          {et.alignment == "right" ? (
                            <div className="row py-3">
                              <div className="col-md-6 p-md-0 order-mobile-first">
                                <div className="content-box-left text-white">
                                  <h4>{et.title}</h4>
                                  <h1>{et.sub_title}</h1>
                                  {ReactHtmlParser(et.content)}
                                </div>
                              </div>
                              <div className="col-md-6 p-md-0 order-mobile-last">
                                <img src={et.imageFileUrl} />
                              </div>
                            </div>
                          ) : et.alignment == "left" ? (
                            <div className="row my-3 " key={index}>
                              <div className="col-md-6 p-md-0 order-mobile-last">
                                <img src={et.imageFileUrl} />
                              </div>
                              <div className="col-md-6 p-md-0 ">
                                <div className="content-box-left text-white order-mobile-first">
                                  <h4>{et.title}</h4>
                                  <h1>{et.sub_title}</h1>
                                  {ReactHtmlParser(et.content)}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row my-3" key={index}>
                              <div className="col-md-12">
                                <div className="content-box-left text-center text-white">
                                  <h4>{et.title}</h4>
                                  <h1>{et.sub_title}</h1>
                                  {ReactHtmlParser(et.content)}
                                </div>
                              </div>
                            </div>
                          )}
                          <span className="d-none">{count++}</span>
                        </div>
                      ) : et.tab == "image" ? (
                        <div className="container-fluid">
                        <div className="row my-3 d-block" key={index}>
                          <div className="col-md-12">
                            <div className="text-center mb-2 px-3">
                              {ReactHtmlParser(et.content)}
                            </div>
                            <img src={et.onlyImageFileUrl} key={index} />
                          </div>
                        </div>
                        </div>
                      ) : (
                        ""
                      );
                  }
                  return <div key={index}>{html}</div>;
                })}
  
                {this.state.packageData.map((pk, index) => {
                  return (
                    <div key={pk.id}>
                      <div className="bg-theme for-tablet-p-remove">
                        <div className="container-fluid">
                          <div className="row py-md-2">
                            <div className="col-md-1 d-flex align-items-center ml-md-3">
                              <img
                                src={this.state.Data.countryImageUrl}
                                className="img-fluid flag hide-mobile"
                              />
                            </div>
                            <div className="col-md-10">
                              <div className="content-box-left  pt-md-4 pb-md-4 pt-sm-2 pb-sm-2 text-mobile-size">
                                <div className="event-flag-mobile-show">
                                  <img
                                    src={this.state.Data.countryImageUrl}
                                    className="img-fluid flag show-mobile"
                                  />
                                  <h4 className="text-white">
                                    {pk.packageDateFormatted}
                                  </h4>
                                </div>
                                <h1 className="text-dark mb-0">{pk.title}</h1>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="row my-3">
                          <div className="col-md-9 p-md-0">
                            <div className="content-box-left text-light">
                              <div
                                className={`alert bg-red ${
                                  pk.highlighted_message_blink == 1 ? "blink" : ""
                                }`}
                                role="alert"
                              >
                                {pk.tickets == 0 || pk.package_status == 2
                                  ? pk.highlighted_message
                                  : pk.highlighted_message}
                              </div>
                              {ReactHtmlParser(pk.description)}
                            </div>
                          </div>
                          <div className="col-md-3 text-center py-4 border-bottom-sec-left">
                            <h3 className="text-white">TICKET AVAILABILITY</h3>
                            {pk.package_status != 2 &&
                            pk.package_status != 3 &&
                            pk.tickets != 0 ? (
                              <div
                                className={`lights ${(dynamicClass =
                                  pk.tickets >= 10 && pk.package_status != 2
                                    ? "green"
                                    : pk.tickets >= 1 &&
                                      pk.tickets <= 9 &&
                                      pk.package_status != 2
                                    ? "amber"
                                    : "red")}`}
                              />
                            ) : (
                              ""
                            )}
                            <h3
                              className={` ${
                                pk.package_status == 2 || pk.tickets == 0
                                  ? "mt-5 f-25 text-red"
                                  : pk.package_status == 3
                                  ? "mt-5 f-25 text-success"
                                  : "mt-4  text-white"
                              }`}
                            >
                              {pk.package_status == 1 &&
                              pk.tickets != 0 &&
                              dynamicClass == "green"
                                ? "GOOD TICKET AVAILABILITY"
                                : pk.package_status == 3
                                ? "COMING SOON"
                                : pk.package_status == 1 &&
                                  pk.tickets != 0 &&
                                  dynamicClass == "amber"
                                ? "OUR TICKET ALLOCATION IS GETTING LOW"
                                : pk.tickets == 0 || pk.package_status == 2
                                ? "SOLD OUT"
                                : ""}
                            </h3>
                            {pk.package_status == 1 && pk.tickets != 0 ? (
                              <>
                                <div className="d-flex justify-content-center">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => this.addToCart(pk.id, e)}
                                    className="buy-now-button buy-now-standard "
                                  />
                                </div>
                                <h2>£{parseFloat(pk.price).toFixed(2)}</h2>
                                {/* <h2>£{pk.price}.00</h2> */}
                                <p>
                                  Prices are per person, including VAT where
                                  applicable.
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                            {pk.is_installment == 1 &&
                            pk.package_status == 1 &&
                            pk.tickets != 0 ? (
                              <>
                                <hr className="events-hr" />
                                <div className="event-payment-type-or">OR</div>
                                <div className="d-flex justify-content-center">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => this.addToCartIns(pk.id, e)}
                                    className="buy-now-button buy-now-instalments"
                                  />
                                </div>
                                <h2>£{parseFloat(pk.initial_installment_payment).toFixed(2)}</h2>
  
                                <p>
                                  Pay in {pk.no_of_installments} instalments with
                                  an initial deposit of £
                                  {parseFloat(pk.initial_installment_payment).toFixed(2)} and then{" "}
                                  {pk.no_of_installments - 1} payments of £
                                  {parseFloat(pk.installment_cost).toFixed(2)} Full details of this
                                  offer will be confirmed at checkout.{" "}
                                </p>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );
      } else {
        return <div></div>;
      }
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
    cart_item_count: state.cart_item_count,

  };
};

const mapDispatchToProps = (dispatch) => {
   
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
    updateCartQuantity: (quantity) => dispatch({ type: "CART_ITEM_QUANTITY", value: quantity }),

  };
};

//  export default connect(mapStateToProps, mapDispatchToProps)(Eventdetail)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChildEventdetail));
