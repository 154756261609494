import React, { Component } from "react";
import {  useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Collapse from "react-bootstrap/Collapse";
import Cookies from "universal-cookie";
import globalData from "../config.json";
import Loading from "./Loading";
import queryString from 'query-string';
import axios from "axios";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';


const cookies = new Cookies();
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    const location = useLocation();
    return <Children {...props} match={match} location={location}/>;
  };
}

class InvoiceOrderProcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      order: {},
      orderitem: [],
    };
    this.handleScroll = this.handleScroll.bind(this);
  }


  loadScript() {
    let scripts = [{ src: globalData.apiUrl + "/front/js/app-html.js" }];

    //Append the script element on each iteration

    scripts.forEach((item) => {
      const script = document.createElement("script");
      script.id = "myScript";
      script.src = item.src;

      document.body.appendChild(script);
    });
  }

  componentDidMount() {

    console.log(this.props);
   
    this.getPaymentResponce(queryString.parse(this.props.location.search));
    // window.scrollTo(0, 0);
    this.loadScript();
    // if (localStorage.getItem("token"))
    //   this.getAuth(localStorage.getItem("token"));

    //this.getOrderData();
    // window.addEventListener("scroll", this.handleScroll);
  }

  getPaymentResponce(data) {
    let token = " ";
    
    if (localStorage.getItem("invoice_token")) {
      token = localStorage.getItem("invoice_token");
     let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
    
    axios
      .post(
        globalData.apiUrl + `/api/invoice/order/barclays/${this.props.match.params.string}`,
        data,
        config
      )
      .then((result) => {
        // window.location.reload()
        // console.log(result);
        // console.log(globalData.redirectUrl+'/order-success/'+result.data.data.confirmationId);    
        if (typeof result.data.status && this.props.match.params.string == "accept"){

          window.location.href = globalData.redirectUrl+'invoice/order/invoice-sucess';
          // window.open();

        }else if(this.props.match.params.string == "cancel"){
          window.location.href = globalData.redirectUrl+'checkout';
        }else if(this.props.match.params.string == "decline"){
          window.location.href = globalData.redirectUrl+'checkout';
        }else if(this.props.match.params.string == "exception"){
          window.location.href = globalData.redirectUrl+'checkout';

        }
       
      })
      .catch((err) => console.error(err));
    }
  }

  handleScroll() {
    // THEME.header.stickyHeaderInit(".hdr-content-sticky");
  }

  handleClick(e) {}

  componentDidUpdate() {}

  componentWillUnmount() {
    const script = document.getElementById("myScript");
    script.parentNode.removeChild(script);
  }

  render() {
    return (
      <>
        { this.state.loading ?
            <Loading />
            : ''
        }
        <div className="page-content">
          <div id="checkout" className="bg-dark">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-11 col-sm-9 col-md-7 col-lg-6 col-xl-9 text-center p-0 mt-3 mb-2">
                  <div className="card px-0 for-p-5 pb-0 mt-3 mb-3">
                    <h2 id="heading">Invoice</h2>

                    <h3 className="text-center text-dark">Payment Processing</h3>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartCount: state.cart_count,
    userData: state.user_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCartCount: (count) => dispatch({ type: "CART_COUNT", value: count }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceOrderProcess));
